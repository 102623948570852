import { Component, OnInit } from '@angular/core';
import {NavService} from '@core/nav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  username: string;

  constructor(public nav: NavService) {}
  pcUrl = this.nav.env.pcUrl;

  ngOnInit() {
  }
}
