<div class="footer">
  <div class="footer_box contentCenter" fxLayout="row wrap" fxLayoutAlign="space-between start">

      <app-menu-list class="footer_box_menu" fxFlex="100" fxFlex.gt-sm="53"></app-menu-list>

    <div class="footer_box_info" fxFlex="100" fxFlex.gt-sm="47">
      <p class="footer_txt">
        Your investment is at stake. We observe that our services are appropriate for the investors that are interested
        in short term exposure to financial assets, who want to diversify their portfolio and have adequate knowledge
        about the risk. It is though possible to lose more than your investment in each trade; however, losses cannot
        exceed your total equity.
      </p>
    </div>
    <div class="footer_box_btm" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end start">
      <!--<div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <a href="" class="social_btn ico_facebook"></a>
        <a href="" class="social_btn ico_twitter"></a>
      </div>-->
      <p class="footer_txt m_t_mob">© {{currentYear}} Forex All Rights Reserved</p>
    </div>
  </div>
</div>


