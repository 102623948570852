import {NgModule} from '@angular/core';

import {SharedModule} from '@shared/shared.module';
import {AppFormModule} from '@view/form/appForm.module';

import { HomeComponent } from '@view/web/home/home.component';

import { BestForexBrokersComponent } from './best-forex-brokers/best-forex-brokers.component';
import { EcnBrokersComponent } from './ecn-brokers/ecn-brokers.component';
import {ViewModule} from '@view/view.module';
/*import { ContactUsComponent } from './contact-us/contact-us.component';*/


@NgModule({
  declarations: [
    HomeComponent,
   /* ContactUsComponent,*/
    BestForexBrokersComponent,
    EcnBrokersComponent,
  ],
    imports: [
        SharedModule,
        AppFormModule,
      //  DragDropModule,
      //  MatExpansionModule,
        ViewModule,
    ]
})
export class WebModule {
}
