<mat-sidenav-container fullscreen >

  <mat-sidenav class="list_mob_menu" #sidenav mode="isMobile.matches ? 'side' : 'push'" role="navigation" position="end">

    <mat-icon class="list_mob_menu_close" (click)="sidenav.toggle()">close</mat-icon>




    <app-menu-list ></app-menu-list> <!-- (click)="sidenav.toggle()" -->

  </mat-sidenav>

  <mat-sidenav-content role="main" >

    <mat-toolbar color="primary" class="header" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div fxFlex="100">
        <app-header class="contentCenter header_top" fxLayout="row wrap" fxLayoutAlign="space-between center"></app-header>
        <button class="mob_menu_btn" mat-icon-button (click)="sidenav.toggle()" aria-label="Mobile Menu">
          <mat-icon class="mob_menu_btn_ico">menu</mat-icon>
        </button>
      </div>
    </mat-toolbar>
    <main [style.min-height.px]="navService.clientHeight-256">
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
    <app-top-button class="btn_to_top"></app-top-button>
  </mat-sidenav-content>
</mat-sidenav-container>
