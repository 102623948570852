<div class=" error404_top">
  <div class="contentCenter">
    <div class="top_404" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="error404|top header">404<br>
        We Couldn’t Find This Page.</h1>
      <p class="width530 m_t_mob">Uh oh, we can't seem to find the page you're looking for. Try going back to the previous page or see our Home Page</p>
      <div class="m_t_mob" fxFlex="100">
        <a routerLink="/" class="btn btn_blue_small" rel="dofollow noreferrer" i18n-aria-label="error btn 1|@@error_btn1" aria-label="HOME PAGE" i18n="@@error_btn1">HOME PAGE</a>
      </div>
    </div>
  </div>
</div>
