import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '@shared/shared.module';
import {EmailFormComponent} from '@view/form/emailForm.component';


import {MatInputModule} from '@angular/material/input';



@NgModule({
  declarations: [
    EmailFormComponent
  ],
  imports: [
    FormsModule, ReactiveFormsModule,
    // MatAutocompleteModule,
    // MatBadgeModule,
    // MatButtonToggleModule,
    // MatDatepickerModule,
    // MatCheckboxModule,
    MatInputModule,
    // MatSelectModule,

    SharedModule,
    // NgxMatFileInputModule
  ],
  exports: [
    EmailFormComponent
  ]
})
export class AppFormModule {
}
