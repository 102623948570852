import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {ErrorComponent} from '@view/general/error/error.component';
import {WebLayoutComponent} from '@view/layout/web-layout/web-layout.component';
import {HomeComponent} from '@view/web/home/home.component';

import {BestForexBrokersComponent} from '@view/web/best-forex-brokers/best-forex-brokers.component';

/*import {ContactUsComponent} from '@view/web/contact-us/contact-us.component';*/
import {EcnBrokersComponent} from '@view/web/ecn-brokers/ecn-brokers.component';


const AppRoutes: Routes = [
  {
    path: '',
    component: WebLayoutComponent,
    data: {
      menu: 'FXBrokers',
      title: 'FXBrokers',
      description: 'FXBrokers',
    },
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          menu: 'Home',
          title: 'FXBrokers — Forex Trading in Dubai, Best Regulated Forex Brokers',
          description: 'Forex Trading in Dubai with UAE Forex Brokers - Find our list of the Best Regulated Forex Brokers in UAE to start your Forex Trading in the region. 24/7 Forex Support within UAE, our office is Located in Dubai.',
        }
      },
      {
        path: 'best-forex-brokers.html',
        component: BestForexBrokersComponent, pathMatch: 'full',
        data: {
          menu: 'Best Forex Brokers',
          title: 'The Best Forex Brokers in UAE for 2020 - Top Forex Trading Companies',
          description: 'Find our list of the Best and Top Forex brokers in UAE for 2020 – Start your Forex Trading with our Trusted and regulated Forex companies / Brokers.',
        }
      },
      {
        path: 'ecn-brokers.html',
        component: EcnBrokersComponent, pathMatch: 'full',
        data: {
          menu: 'ECN Brokers',
          title: 'The Best ECN Forex Brokers in UAE for 2020, ECN Forex Trading in Dubai',
          description: 'Find our list of the Best and Top ECN Forex brokers in UAE for 2020 – Start your Forex Trading with our Trusted and regulated ECN Brokers today. 24/7 Forex Support within UAE, our office is Located in Dubai.',
        }
      },
      /*{
        path: 'contact-us.html',
        component: ContactUsComponent, pathMatch: 'full',
        data: {
          menu: 'Contact Us',
          title: 'Contact us – Visit our Dubai office For Forex Help',
          description: 'Looking for Forex help? Visit our Dubai office, Office # 1711 Clover Bay Tower Abraj Street Business Bay Dubai, UAE or call us at +971 4 580 7617.',
        }
      },*/
      {path: 'error', component: ErrorComponent},
      {path: '**', component: ErrorComponent} // otherwise redirect to ErrorComponent

    ]
  }
];

// export class PreloadModules implements PreloadingStrategy {
//
//   preload(route: Route, load: () => Observable<boolean>): Observable<boolean> {
//     if (route.data && route.data.noPreLoad) {
//       return of(null);
//     }
//     return of(true).pipe(delay(2000), switchMap((_: boolean) => load()));
//   }
//
// }
// ExtraOptions - https://angular.io/api/router/ExtraOptions
@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, {
    //  preloadingStrategy: PreloadModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy',
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRouting {
}

