<div >
  <a routerLink="/" class="logo_header" title="FXBrokers" aria-label="FXBrokers"></a>
</div>
<div  fxLayout="row wrap" fxLayoutAlign="end center">
  <app-menu ></app-menu>




</div>
