import {Component, OnInit} from '@angular/core';
import {NavService} from '@core/nav.service';

@Component({
  selector: 'app-ecn-brokers',
  templateUrl: './ecn-brokers.component.html',
  styleUrls: ['./ecn-brokers.component.scss']
})
export class EcnBrokersComponent implements OnInit {
  currentYear;
  currentMonth;
  arrayMonth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  constructor(public nav: NavService) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.currentMonth = this.arrayMonth[new Date().getMonth()];
  }

}
